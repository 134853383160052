<template>
    <div class="description-container">
        <div class="description-sub-container">
            <h2 class="title">Цели и задачи</h2>
            <div class="cards-sub-container">
                <div class="card">
                    <h3 class="card-title">Повышение безопасности</h3>
                    <p class="card-text">
                        Система предоставляет возможность дольше работать безаварийно, уменьшая время простоя конвейера из-за аварий.
                    </p>
                </div>

                <div class="card">
                    <h3 class="card-title">Улучшение качества обслуживания</h3>
                    <p class="card-text">
                        Система помогает своевременно выявлять и устранять нагревы и сбои в работе оборудования, что повышает общую эффективность работы конвейерной системы.
                    </p>
                </div>

                <div class="card">
                    <h3 class="card-title">Снижение затрат</h3>
                    <p class="card-text">
                        Предотвращение серьезных повреждений конвейерной ленты и роликов, а также связанных с ними затрат на ремонт и замену.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "DescriptionAndObjectivesHeatingOfLenta",
  data() {
    return {
     
    }
  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.description-container{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 3vh;
    flex-direction: column;
    align-items: center;
    color: var(--text);

}

.description-sub-container{
    display: flex;
    max-width: 1300px;
    flex-direction: column;
    padding-bottom: 4vh;

}

.title{
    font-size: clamp(30px, 4.8vw, 50px);
    font-family: geometria_extralight;
    padding-top: 2vh;
    text-transform: uppercase;
    text-align: center;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-top: 3vh;
}

.cards-sub-container{
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    column-gap: 2vh;
    width: 100%;
    padding-top: 1vh;
}

.card{
    display: flex;
    flex-direction: column;
    width: 33%;
    border-radius: 20px;
    color: var(--text);
}

.card-title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-top: 2vh;
    text-align: center;
}

.card-text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding: 3vh 2vh 0 0;
    text-align: center;
}

@media (max-width: 1300px){
    .description-container{
        width: calc(100% - 4vh);
        margin-left: 2vh;
    }

} 

@media (max-width: 800px){
    .cards-sub-container{
        flex-direction: column;
        row-gap: 2vh;
    }
    .card{
        width: 100%;
        align-items: center;
    }

    .card-text{
        text-align: center;
    }
} 

</style>