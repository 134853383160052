<template>
    <div class="cards-container">
      <div class="cards-sub-container">
        <div v-for="(card, index) in cardsText" :key="index" class="card">
          <img class="card-image" :src="images[index]" alt="">
          <h3 class="title">{{ card.title }}</h3>
          <p class="text">{{ card.text }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProductCards",
    props: {
      cardsText: {
        type: Array,
        default: () => [],
      },
    },
    data() {
        return {
            images: [
                require('@/assets/setings.svg'),
                require('@/assets/(c).svg'),
                require('@/assets/cal.svg'),
                require('@/assets/dollar-sign.svg')
            ]
        }
    },
    methods: {

    },
  };
  </script>

<style scoped>

.cards-container{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.cards-sub-container{
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    column-gap: 2vh;
    width: 100%;
    row-gap: 2vh;
    justify-content: center;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 25%;
    background: var(--slider-lenta-bg);
    border-radius: 20px;
    color: var(--text);
    padding-bottom: 2vh;
    position: relative;
    min-height: 22vh;
}

.card-image{
    position: absolute;
    top: 1vh;
    right: 1vh;
    width: 10vh;
}

.title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-left: 2vh;
    padding-top: 8vh;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding: 1vh 2vh 0 2vh;
}

@media (max-width: 1300px){
    .cards-sub-container{
        flex-wrap: wrap;

    }

    .cards-container{
        width: calc(100% - 4vh);
        margin-left: 2vh;
    }

    .card{
        min-width: 320px;

    }

} 



@media (max-width: 800px){
    .cards-sub-container{
        flex-direction: column;
        row-gap: 2vh;
    }
    .card{
        width: 100%;
        height: 15vh;
    }
} 

</style>