<template>
    <div class="description-container">
        <div class="description-sub-container">
            <h2 class="title">Цели и задачи</h2>
            <div class="cards-sub-container">
                <div class="card">
                    <div class="left-container">
                        <img class="devices" :src="require('@/assets/IncreasedSecurity.svg')" alt="">
                    </div>
                    <div class="right-container">
                        <h3 class="card-title">Повышение безопасности</h3>
                        <p class="card-text">
                            Определение людей помогает своевременно обнаружить нарушения, уменьшить количество несчастных случаев и травматизации сотрудников на предприятии.
                        </p>
                    </div>
                </div>
                <div class="card">
                    <div class="left-container">
                        <img class="devices" :src="require('@/assets/Observation.svg')" alt="">
                    </div>
                    <div class="right-container">
                        <h3 class="card-title">Наблюдение</h3>
                        <p class="card-text">
                            Система обеспечивает дополнительный надзор и видеофиксацию любых нарушений в местах особой опасности.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "DescriptionAndObjectivesDangerZone",
  data() {
    return {
     
    }
  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.description-container{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--text);
    background: var(--about-bg);
    padding-top: 4vh;

}

.description-sub-container{
    display: flex;
    max-width: 1300px;
    flex-direction: column;
    padding-bottom: 4vh;
    padding-top: 4vh;


}

.title{
    font-size: clamp(30px, 4.8vw, 50px);
    font-family: geometria_extralight;
    text-transform: uppercase;
    text-align: center;

}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-top: 3vh;
}

.cards-sub-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1300px;
    column-gap: 2vh;
    width: 100%;
    padding-top: 4vh;
}

.card{
    display: flex;
    flex-direction: row;
    width: 50%;
    border-radius: 20px;
    color: var(--text);
    background-color: var(--default-bg);
    box-shadow: 5px 4px 11.3px rgba(67, 77, 108, 0.1), 57px 108px 49px rgba(0, 0, 0, 0.01), 14px 27px 30px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.04), 4px 7px 17px rgba(0, 0, 0, 0.04), inset -5px -4px 14.6px rgba(68, 80, 105, 0.1);

}
.left-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-container img{
    width: 18vh;
    /* height: 18vh; */
    padding: 2vh;
}

.card-title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-top: 2vh;
    text-align: start;
}

.card-text{
    font-size: clamp(14px, 1.6vh, 50px);
    padding: 3vh 2vh 0 0;
    text-align: start;
}

@media (max-width: 1300px){
    .description-container{
        width: calc(100% - 4vh);
        padding: 0 2vh;
    }
    .cards-sub-container{
        flex-direction: column;
        align-items: center;
        row-gap: 2vh;
    }

    .card{
        width: 100%;
    }

} 

@media (max-width: 800px){
    .cards-sub-container{
        flex-direction: column;
        row-gap: 2vh;
    }
    .card{
        width: 100%;
        min-height: 200px;
        flex-direction: column;
    }

    .card-text, .card-title{
        text-align: center;
        padding: 2vh;
    }

    .devices{
        width: 100% !important;
        padding: 2vh !important;
    }
} 

</style>