<template>
    <div class="container" :style="{background: backgroundColor}">
        <div class="sub-container" :style="{flexDirection: position}">
            <div class="slider-contaier">
                <video class="img"  autoplay loop muted playsinline>
                    <source :src="video" type="video/mp4">
                    Ваш браузер не поддерживает видео.
                </video>
            </div>
            <div class="text-container">
                <h3 class="title">
                    {{ title }}
                </h3>
                <p class="text" v-for="(value, index) in text">
                    {{ value }}
                </p>    
            </div>
        </div>

    </div>  
</template>

<script>
import ImageStackLenta from "@/components/ImageStackLenta.vue";

export default {
  name: "ProductBlock",
  components: {
        ImageStackLenta
    },
  data() {
    return {
     
    }
  },
  props: {
    title: {
        type: String
    },
    text: {
        type: Array
    },
    position: {
        type: String
    },
    video: {
        type: String
    },
    backgroundColor: {
        type: String
    }

  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    /* background: var(--about-bg); */
    padding: 4vh 0;
    color: var(--text);
}

.sub-container{
    display: flex;
    max-width: 1300px;
    width: 100%;
    align-items: center;
    justify-content: center;
    column-gap: 4vh;
}

.text-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center ;
}

.title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-bottom: 2vh;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-top: 2vh;
}

.img{
    max-width: 100%;
    min-width: 320px;
    max-height: 100%;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: 57px 108px 49px rgba(0, 0, 0, 0.01), 14px 27px 30px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.04), 4px 7px 17px rgba(0, 0, 0, 0.04);

}


.slider-contaier{
    width: 50%;
}

@media (max-width: 1300px){
    .sub-container{
        width: calc(100% - 4vh);
        padding: 0 2vh;
    }

} 


@media (max-width: 800px){
    .sub-container{
        row-gap: 2vh;
        align-items: center;
        flex-direction: column-reverse !important;
    }

    .text-container{
        width: 100%;
    }

    .slider-contaier{
        width: 100%;
    }
} 

</style>