<template>
    <div class="product-container">
        <Header />
        <Breadcrumbs
            :path="[
                {name: 'Главная', url: '/'},
                {name: 'Каталог', url: '/catalog'},
                {name: category_ru.charAt(0).toUpperCase() + category_ru.slice(1).replace('_' , ' '), url: `/catalog/${category.charAt(0).toLowerCase() + category.slice(1)}`},
                {name: title, url: '/'},
            ]"
        />
        <div>
            <HeaderHeatingOfLenta :image="imageBase64" :title="title"/>
            <ProductCards :cardsText="cardsText"/>
            <DescriptionAndObjectivesHeatingOfLenta/>
            <NeedToHeatingOfLenta/>
            <ProductBlock 
              :title="'Оповещение оператора'" 
              :text="['При обнаружении нагрева система мгновенно уведомляет оператора о событии, что позволяет своевременно принимать меры для устранения проблемы. ', 
              'Все приходящие уведомления хранятся и доступны для просмотра с фото/видео в журнале уведомлений.']" 
              :position="'row'" 
              :video="require('@/assets/HeatingOfLentaVideos/1.webm')"
            />
            <ProductBlock 
              :title="'Поддержка нескольких конвейеров'" 
              :text="['Поддерживается наличие нескольких конвейеров на шахте, собирая всю доступную информацию по каждому из них в отдельности. ', 
              'Переключайтесь между разными конвейерами чтобы увидеть данные по одному конкретному конвейеру.']" 
              :position="'row-reverse'" 
              :video="require('@/assets/HeatingOfLentaVideos/2.webm')"
              :backgroundColor="'#F5F7F9'"
            />
            <ProductBlock 
              :title="'Удобство работы'" 
              :text="['Вся информация отображается на некоторых страницах по каждому из конвейеров одновременно и в удобном формате без лишних действий.', 
              'Просматривайте состояние оборудования или ознакомьтесь с нагревами роликов и полотна сразу по всем конвейерам.']" 
              :position="'row'" 
              :video="require('@/assets/HeatingOfLentaVideos/3.webm')"
            />
            <ProductBlock 
              :title="'Простота работы с перегревами'" 
              :text="['Нагревы ленточного полотна и роликов, обнаруженные тепловизионными камерами, отображаются на виртуальной схеме конвейера в режиме реального времени без задержек и перемещаются в соответствии со скоростью конвейерной ленты. Кликните на нагрев или участок схемы ленты чтобы увидеть больше доступной информации о нём.']" 
              :position="'row-reverse'" 
              :video="require('@/assets/HeatingOfLentaVideos/4.webm')"
              :backgroundColor="'#F5F7F9'"
            />
            <ProductBlock 
              :title="'Аналитика данных'" 
              :text="['Система собирает данные, полученных за период времени, о температуре на конвейере и визуализирует их в виде удобных графиков и схем. Все графики и схемы доступны для скачивания в разных форматах.', 
              'Журнал событий записывает и хранит в себе все события превышения температуры, зафиксированные на конвейерах. Так же вы можете управлять данными которые хотите видеть в журнале и скачивать его в формате Excel.']" 
              :position="'row'" 
              :video="require('@/assets/HeatingOfLentaVideos/5.webm')"
            />
            <ProductBlock 
              :title="'Настройка оборудования'" 
              :text="['Любая неполадка в работе оборудования оперативно отображается на состоянии конвейера. ', 
              'Настраивайте всё оборудование связанное с системой прямо в веб-интерфейсе, включая связь конвейеров с УШК, камеры, датчики и источники питания, стоящие на конвейере.']" 
              :position="'row-reverse'" 
              :video="require('@/assets/HeatingOfLentaVideos/6.webm')"
              :backgroundColor="'#F5F7F9'"
            />
            <ProductBlock 
              :title="'Запись и хранение данных'" 
              :text="['Система отображает на странице Видеоархива все собранные записи с нагревами роликов и перегревами полотна, сортирует их по дате, отображая на временной линии, показывает как фото фиксацию так и видео.', 
              'Данные хранятся до 1 года.']" 
              :position="'row'" 
              :video="require('@/assets/HeatingOfLentaVideos/7.webm')"
            />
            <ProductBlock 
              :title="'Поддержка пользователей'" 
              :text="['Система поддерживает большое количество пользователей с разными ролями доступа к тем или иным частым системы, такие как Администратор, Пользователь и Гость.', 
              'Создание и настройка пользователей доступны Администраторам прямо через веб-интерфейс на остальной странице.']" 
              :position="'row-reverse'" 
              :video="require('@/assets/HeatingOfLentaVideos/8.webm')"
              :backgroundColor="'#F5F7F9'"
            />
            <CarouselDangerZone/>

            <DocumentationProductPage
              v-if="arrayHeaderImageNamesDocument.uploadedNamesDocument && arrayHeaderImageNamesDocument.headerImageBase64Document"
              :title="$store.state.lang === 'ru-RU' ? 'Документация' : 'Documentation'"
              :names="this.arrayHeaderImageNamesDocument.uploadedNamesDocument"
              :arrayElement="this.arrayHeaderImageNamesDocument.headerImageBase64Document"
              :id="productID"
            />
            <ApplicationHeatingOfLenta/>

            <CardCarousel
                    :title="$store.state.lang == 'ru-RU' ? 'РЕКОМЕНДУЕМОЕ ИЗ ТОЙ ЖЕ КАТЕГОРИИ' : 'RECOMMENDED FROM THE SAME CATEGORY'"
                    :cards="recommendedCard.filter(card => card.id != productID)"
                    :link="`/product/`"
                    :category_type="category"
                    :description="''"
                    v-if="recommendedCard.length > 1"
            />

        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HeaderHeatingOfLenta from "@/components/Product/HeaderHeatingOfLenta.vue";
import DescriptionProduct from "@/components/Product/DescriptionProduct.vue";
import SpecificationsProduct from "@/components/Product/SpecificationsProduct.vue";
import RecommendedProduct from "@/components/Product/RecommendedProduct.vue";
import {HTTP} from "@/plugins/http-common";
import CardCarousel from "@/components/CardCarousel.vue";
import Parser from "@/components/Parser/Parser.vue";
import SkeletonHeaderProduct from "@/components/Sceletons/SkeletonHeaderProduct.vue";
import SkeletonParser from "@/components/Sceletons/SkeletonParser.vue";
import {useHead} from "@vueuse/head"
import Breadcrumbs from "@/components/ui/Breadcrumbs.vue"
import DocumentationProductPage from "@/components/DocumentationProductPage.vue";
import ProductCards from "@/components/ProductCards.vue";
import DescriptionAndObjectivesHeatingOfLenta from "@/components/DescriptionAndObjectivesHeatingOfLenta.vue";
import DefectsDetection from "@/components/DefectsDetection.vue";
import OperatorAlert from "@/components/ OperatorAlert.vue";
import SafetyLenta from "@/components/SafetyLenta.vue";
import NeedToHeatingOfLenta from "@/components/NeedToHeatingOfLenta.vue";
import PipelineSupport from "@/components/PipelineSupport.vue";
import SimplificationOfWork from "@/components/SimplificationOfWork.vue";
import DataStructuring from "@/components/DataStructuring.vue";
import StorageAndReporting from "@/components/ StorageAndReporting.vue";
import RecordingTheHstory from "@/components/RecordingTheHstory.vue";
import UserSupport from "@/components/ UserSupport.vue";
import EaseOfOperation from "@/components/EaseOfOperation.vue";
import CarouselDangerZone from "@/components/Slider/CarouselDangerZone.vue";
import ApplicationHeatingOfLenta from "@/components/ApplicationHeatingOfLenta.vue";
import ProductBlock from "@/components/ProductBlock.vue"

export default {
    name: "HeatingOfLenta",
    components: {
        DocumentationProductPage,
        SkeletonParser,
        SkeletonHeaderProduct,
        Parser,
        RecommendedProduct,
        SpecificationsProduct,
        DescriptionProduct,
        HeaderHeatingOfLenta,
        Footer,
        Header,
        CardCarousel,
        Breadcrumbs,
        ProductCards,
        DescriptionAndObjectivesHeatingOfLenta,
        DefectsDetection,
        OperatorAlert,
        SafetyLenta,
        NeedToHeatingOfLenta,
        PipelineSupport,
        SimplificationOfWork,
        DataStructuring,
        StorageAndReporting,
        RecordingTheHstory,
        UserSupport,
        EaseOfOperation,
        CarouselDangerZone,
        ApplicationHeatingOfLenta,
        ProductBlock
        
    },
    data() {
        return {
            // Header Product
            imageBase64: [],
            header: [],
            title: "",
            titleEN: "",
            characteristicRU: "",
            category: '',
            category_ru: '',
            characteristicEN: "",

            // Description Product
            titleDescription: '',

            description: '',
            keywords: '',

            titleDistinctiveFeatures: '',
            ArrayList: [],

            titleSystemComposition: '',
            SystemComposition: "",

            titleScopeOfApplication: '',
            ScopeOfApplication: '',


            arrayHeaderImageNamesDocument: {
              headerImageBase64Document: [],
              uploadedNamesDocument: []
            },

            //
            recommendedCard: [],

            errorStatus: false,

            cardsText: [
              {
                  title: 'Область',
                  text: 'Применяется в конвейерах ленточного типа в подземных выработках шахт, а также промышленных и горно-технологических объектах.'
              },
              {
                  title: 'Правообладатель',
                  text: 'Все права на реализацию программного обеспечения принадлежат ООО “Трансмаш”.'
              },
              {
                  title: 'Срок поддержки',
                  text: '1 год после полной установки на вашем производстве.'
              },
              {
                  title: 'Цена',
                  text: 'Рассчитывается индивидуально. Оставьте заявку или свяжитесь с нами.'
              },
          ]

            
        }
    },
    props: {
        productID: {
            default: '1'
        },
    },

    methods: {
      async loadProductData(productID, lang) {
        try {
          const response = await HTTP.get(`/catalog/get_catalog_by_id/${productID}${lang}`);
          const responseData = response.data;

          const imagePaths = JSON.parse(responseData.image).image;
          this.imageBase64 = await this.loadImages(imagePaths);

          for (let i in responseData.body) {
            this.header[i] = responseData.body[i];
          }

          this.title = responseData.title;
          this.category = JSON.parse(responseData.category).category_eng;
          this.category_ru = JSON.parse(responseData.category).category_ru;
          console.log(responseData);
          

          return responseData;
        } catch (error) {
          console.error('Ошибка при загрузке данных продукта:', error);
          throw error;
        }
      },
      async loadRecommendedCards(category) {
        try {
          const response = await HTTP.get(`/catalog/get_by_category${category}`);
          const results = await Promise.all(
            response.data.map(async (item) => {
              const parsedImage = JSON.parse(item.image).image[0];
              const formData = new FormData();
              formData.append('image', parsedImage);

              const postResponse = await HTTP.post('/get_image/download_image', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob'
              });

              const url = URL.createObjectURL(postResponse.data);
              return {
                id: item.id,
                title: item.title,
                category: item.category,
                title_transcription: item.title_transcription,
                image: url
              };
            })
          );

          this.recommendedCard = results;
        } catch (error) {
          console.error('Ошибка при загрузке рекомендованных карточек:', error);
        }
      },
      async loadPDF(productID) {
        try {
          const response = await HTTP.get(`/catalog/get_pdf_by_id/${productID}`);
          this.arrayHeaderImageNamesDocument.headerImageBase64Document = JSON.parse(JSON.parse(response.data.pdf).pdf);
        } catch (error) {
          console.error('Ошибка при загрузке PDF:', error);
          this.errorStatus = true;
        }
      },

      async loadPDFTitle(productID) {
        try {
          const response = await HTTP.get(`/catalog/get_pdf_title_by_id/${productID}`);
          this.arrayHeaderImageNamesDocument.uploadedNamesDocument = response.data.title_ru;
          console.log(response.data.title_ru);
          
        } catch (error) {
          console.error('Ошибка при загрузке названия PDF:', error);
          this.errorStatus = true;
        }
      },
      async loadImages(imagePaths) {
        const imagePromises = imagePaths.map((imagePath, index) => {
          const formData = new FormData();
          formData.append('image', imagePath);

          return HTTP.post('/get_image/download_image', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob'
          }).then(postResponse => {
            const url = URL.createObjectURL(postResponse.data);
            return { url, index };
          });
        });

        const results = await Promise.all(imagePromises);
        results.sort((a, b) => a.index - b.index);
        return results.map(result => result.url);
      },
    },

    async created() {
    
      try {
        const lang = this.$store.state.lang === 'ru-RU' ? 'ru' : 'eng';
        const responseData = await this.loadProductData(this.productID, lang);

        const categoryType = JSON.parse(responseData?.category_transcription).category_ru.replaceAll(' ', '_');
        const cardName = responseData?.title_transcription.replaceAll(' ', '_');
        useHead({
          title: responseData?.title,
          meta: [
            {
              name: `description`,
              content: JSON.parse(responseData?.description)?.description
            },
            {
              name: `keywords`,
              content: JSON.parse(responseData?.keywords)?.keywords.join()
            },
            { 
              property: 'og:title', 
              content: responseData.title
            },
            { 
              property: 'og:description', 
              content: JSON.parse(responseData?.description)?.description
            },
            { 
              property: 'og:image', 
              content: JSON.parse(responseData?.image)?.image[0] 
            }, 
            { 
              property: 'og:url', 
              content: `${window.location.origin}/catalog/${categoryType}/${cardName}?id=${this.productID}`
            },
            { 
              property: 'og:type', 
              content: 'product' 
            }
          ]
        });

        await this.loadRecommendedCards(this.category);

        await this.loadPDF(this.productID);
        await this.loadPDFTitle(this.productID);

        this.$watch(
          () => this.productID,
          async () => {
            this.title = '';
            this.imageBase64 = [];
            this.header = [];
            this.category = '';
            this.category_ru = '';

            try {
              await this.loadProductData(this.productID, lang);
            } catch (error) {
              console.error('Ошибка при загрузке данных продукта:', error);
            }

            document.getElementsByClassName('product-container')[0].scrollTop = 0;
          }
        );

      } catch (error) {
        console.error('Ошибка при инициализации компонента:', error);
      }
    }



}
</script>

<style scoped>
.product-container {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
}

</style>