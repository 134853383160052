<template>
    <div class="cards-container">
        <h2 class="title">
            Всё что нужно знать о конвейере
        </h2>
        <div class="cards-sub-container">
            <div class="card">
                <img class="card-image" :src="require('@/assets/heatingOfLentaScreen1.svg')" alt="">
                <h3 class="title-card">Отслеживание температуры полотна</h3>
                <p class="text">
                    Отслеживание нагрева ленточного полотна, его точного расположения, запись температуры превышающей пороговые значения в аналитику.
                </p>
            </div>

            <div class="card">
                <img class="card-image" :src="require('@/assets/heatingOfLentaScreen2.svg')" alt="">
                <h3 class="title-card">Обнаружение нагретых роликов</h3>
                <p class="text">
                    Отслеживание неисправных роликов, их точного расположения, запись температуры превышающей пороговые значения в аналитику.
                </p>
            </div>

            <div class="card">
                <img class="card-image" :src="require('@/assets/heatingOfLentaScreen3.svg')" alt="">
                <h3 class="title-card">Контроль состояния конвейера</h3>
                <p class="text">
                    Фиксация скорости, ширины и отслеживание температуры ленточного полотна, подсчёт пройденных лентой метров и прогонов во время диагностики.
                </p>
            </div>
            <div class="card">
                <img class="card-image" :src="require('@/assets/heatingOfLentaScreen4.svg')" alt="">
                <h3 class="title-card">Мониторинг оборудования</h3>
                <p class="text">
                    Отображение состояния оборудования на конвейере, включая настройку камер, датчиков, УШК и другого оборудования.
                </p>
            </div>
        </div>

    </div>
</template>

<script>

export default {
  name: "NeedToKnowLenta",
  data() {
    return {
     
    }
  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.cards-container{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2vh;
    flex-direction: column;
    align-items: center;
    background: var(--about-bg);
    padding-bottom: 4vh;
}

.title{
    font-size: clamp(30px, 4.8vw, 50px);
    font-family: geometria_extralight;
    padding-top: 2vh;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 1vh;
    color: var(--text);
}

.description{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-bottom: 5vh;
    text-align: center;
    color: var(--text);
    
}

.cards-sub-container{
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    column-gap: 2vh;
    width: 100%;
    row-gap: 2vh;
    justify-content: center;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh;
    width: 25%;
    background: var(--card-bg);
    border-radius: 20px;
    color: var(--text);
    padding-bottom: 2vh;
    position: relative;
    box-shadow: 57px 108px 49px rgba(0, 0, 0, 0.01), 14px 27px 30px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.04), 4px 7px 17px rgba(0, 0, 0, 0.04);
}

.card-image{
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.title-card{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-top: 2vh;
    text-align: center;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-top: 2vh;
    text-align: center;

}

@media (max-width: 1300px){
    .cards-sub-container{
        flex-wrap: wrap;
        width: calc(100% - 4vh);
        padding: 0 4vh;

    }

    .card{
        /* width: calc(100% - 8vh); */
        min-width: 300px;
        display: flex;
        align-items: center;
    }


} 



@media (max-width: 800px){
    .cards-sub-container{
        row-gap: 2vh;
    }
    .title{
        width: calc(100% - 4vh);
        padding: 0 2vh;
        padding-bottom: 1vh;
    }

    .description{
        padding-bottom: 2vh;
    }
} 

</style>